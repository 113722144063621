import { FC } from 'react';

export const LoadingLogoViolet: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="250"
      height="103"
      viewBox="0 0 250 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M27.6963 74.1064H42.9861V43.862H73.471V28.6348H27.6963V74.1064Z" fill="#A857F1" />
      <path
        d="M60.2843 75.0158C67.9019 75.0158 74.0773 68.8404 74.0773 61.2227C74.0773 53.605 67.9019 47.4297 60.2843 47.4297C52.6666 47.4297 46.4912 53.605 46.4912 61.2227C46.4912 68.8404 52.6666 75.0158 60.2843 75.0158Z"
        fill="#A857F1"
      />
      <path
        d="M213.288 73.4722C210.471 73.4722 208.305 72.7294 206.788 71.2438C205.303 69.7582 204.56 67.5917 204.56 64.7443V51.281H198.85V43.4352H204.56V33.2217H214.077V43.4352H222.201V51.281H214.077V62.748C214.077 63.7075 214.325 64.4348 214.82 64.93C215.315 65.4252 216.042 65.6728 217.002 65.6728H222.201V73.4722H213.288Z"
        fill="#001B38"
      />
      <path
        d="M167.324 43.4351H175.959V45.9421C176.95 44.8588 178.157 44.0232 179.58 43.4351C181.035 42.8161 182.645 42.4912 184.409 42.4602C186.142 42.4293 187.767 42.7233 189.283 43.3423C190.8 43.9613 192.115 44.8279 193.229 45.9421C194.344 47.0873 195.195 48.4955 195.783 50.1668C196.402 51.8071 196.711 53.8189 196.711 56.2021V73.4722H187.148V57.177C187.148 55.939 187.024 54.9795 186.776 54.2986C186.56 53.5868 186.204 52.9832 185.709 52.488C184.811 51.6214 183.588 51.1881 182.041 51.1881C180.493 51.1881 179.271 51.6214 178.373 52.488C177.847 52.9832 177.46 53.5868 177.213 54.2986C176.996 54.9795 176.888 55.939 176.888 57.177V73.4722H167.324V43.4351Z"
        fill="#001B38"
      />
      <path
        d="M147.21 74.2612C144.084 74.2612 141.236 73.5648 138.667 72.172C136.13 70.8102 134.118 68.9068 132.632 66.4617C131.178 64.0476 130.45 61.3705 130.45 58.4302C130.45 55.4899 131.178 52.7973 132.632 50.3522C134.118 47.9072 136.13 45.9883 138.667 44.5955C141.236 43.1718 144.084 42.46 147.21 42.46C150.336 42.46 153.168 43.1718 155.705 44.5955C158.274 45.9883 160.286 47.9072 161.741 50.3522C163.195 52.7973 163.923 55.4899 163.923 58.4302C163.923 61.3705 163.195 64.0476 161.741 66.4617C160.286 68.9068 158.274 70.8102 155.705 72.172C153.168 73.5648 150.336 74.2612 147.21 74.2612ZM147.21 65.8118C148.51 65.8118 149.67 65.4868 150.692 64.8369C151.744 64.1869 152.564 63.3048 153.152 62.1906C153.74 61.0764 154.034 59.823 154.034 58.4302C154.034 57.0374 153.74 55.784 153.152 54.6698C152.564 53.5556 151.744 52.689 150.692 52.07C149.67 51.42 148.51 51.095 147.21 51.095C145.879 51.095 144.687 51.42 143.635 52.07C142.614 52.689 141.809 53.5556 141.221 54.6698C140.633 55.784 140.339 57.0374 140.339 58.4302C140.339 59.823 140.633 61.0764 141.221 62.1906C141.809 63.3048 142.629 64.1869 143.681 64.8369C144.734 65.4868 145.91 65.8118 147.21 65.8118Z"
        fill="#001B38"
      />
      <path
        d="M109.27 43.4349H117.858V47.0096C118.88 45.7407 120.102 44.7657 121.526 44.0848C122.98 43.4039 124.543 43.0635 126.215 43.0635C127.298 43.0635 128.35 43.1563 129.372 43.342V52.3485C128.629 52.0699 127.607 51.9307 126.308 51.9307C124.017 51.9307 122.207 52.627 120.876 54.0198C120.164 54.7317 119.622 55.5983 119.251 56.6196C118.91 57.641 118.74 58.9563 118.74 60.5657V73.4719H109.27V43.4349Z"
        fill="#001B38"
      />
      <path
        d="M81.8857 28.1611H106.212V37.214H91.6814V46.0812H105.934V54.8091H91.6814V73.472H81.8857V28.1611Z"
        fill="#001B38"
      />
    </svg>
  )
}
