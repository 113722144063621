import { LoadingLogoViolet } from '@/components/pages/signup/LoadingLogoViolet';
import { getRandomQuoteByIndustry, SocialProofQuote } from '@/components/pages/signup/SocialProofQuote';
import { FC, useMemo } from 'react';
import styled from 'styled-components';
import Styled from './components';

interface FullScreenLoaderProps {
  firstName: string;
  industry: string;
}

const StyledLoadingLogoViolet = styled(LoadingLogoViolet)`
  margin-top: 38vh;
  margin-bottom: min(20vh, 270px);
`;

const SignupFullScreenLoader: FC<FullScreenLoaderProps> = ({ firstName, industry }) => {
  const quote = useMemo(
    () => getRandomQuoteByIndustry(industry),
    [industry]
  );

  return (
    <Styled.FullScreenLoader>
      <StyledLoadingLogoViolet />

      <Styled.ProgressBar>
        <div></div>
      </Styled.ProgressBar>

      {quote ? <SocialProofQuote quote={quote} /> : (
        <p>
          Welcome to Front, {firstName}
        </p>
      )}
    </Styled.FullScreenLoader>
  );
};

export default SignupFullScreenLoader;
