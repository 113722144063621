import styled from 'styled-components';

import { COLORS } from '@/styles/color';
import { FONT_WEIGHTS } from '@/styles/typography';
import { remCalc } from '@/styles/typography/utils';

export interface Quote {
  text: string;
  author: string;
  role: string;
  company: string;
}

export interface SocialProofQuoteProps {
  quote: Quote;
}

const StyledQuote = styled.div`
  font-weight: ${FONT_WEIGHTS.NORMAL};
  font-size: ${remCalc(16)};
  width: 100%;
  max-width: 600px;
  text-align: center;
  font-family: system-ui, 'Suisse Intl', -apple-system, sans-serif;
`;

const StyledQuoteText = styled.p`
  margin-bottom: 8px;
  color: ${COLORS.GRAY.LOWLIGHT};
  font-size: 16px;
`;

const StyledQuoteAuthor = styled.p`
  color: ${COLORS.GRAY.LOWLIGHT};
  font-size: 14px;
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  margin: 0 !important;
`;

const StyledQuoteMetadata = styled.span`
  margin-left: 8px;
  color: ${COLORS.GRAY.LOWLIGHT}99;
  font-weight: ${FONT_WEIGHTS.NORMAL};
`;

export const SocialProofQuote = ({ quote }: SocialProofQuoteProps) => (
  <StyledQuote>
    <StyledQuoteText>&ldquo;{quote.text}&rdquo;</StyledQuoteText>
    <StyledQuoteAuthor>
      {quote.author}
      <StyledQuoteMetadata>
        {quote.role}, {quote.company}
      </StyledQuoteMetadata>
    </StyledQuoteAuthor>
  </StyledQuote>
);

export const QUOTES_BY_INDUSTRY = {
  'Technology': [
    {
      text: 'Front helps us maintain that delicate balance between speed and quality.',
      author: 'Mary Ochs',
      role: 'Head of Customer Support',
      company: 'DealDash',
    },
    {
      text: "Using Front, we're able to speedily communicate … when cases need to be escalated. This has allowed us to solve issues much faster.",
      author: 'Guillaume Poirier',
      role: 'VP of Customer Care',
      company: 'Lydia',
    },
    {
      text: 'The organizations we support often laud our attention to customer support… a lot of that can be attributed to how Front keeps us organized.',
      author: 'Justin Reisch',
      role: 'Director of Marketing',
      company: 'Fundraise Up',
    },
    {
      text: 'Front has been a game-changer for the way we work internally.',
      author: 'Justin Reisch',
      role: 'Director of Marketing',
      company: 'Fundraise Up',
    },
    {
      text: 'Front provides the automation we need, allowing us to focus on our core business',
      author: 'Lucian Puca',
      role: 'Automation and Workflow Lead',
      company: 'Mixam',
    },
    {
      text: "We're sending far fewer emails now that we have message comments in Front",
      author: 'Mairtini Ni Dhomhnaill',
      role: 'Founder',
      company: 'Countsy',
    },
    {
      text: 'My team literally could not do what they do without Front. Front gives our team the stability and security to work efficiently and deliver a great experience',
      author: 'Trish Bingham',
      role: 'VP of Client Services',
      company: 'Boostability',
    },
    {
      text: "While most industry standards aim for a 24-hour email first response time, with Front, we've managed to respond twice as fast.",
      author: 'Mary Ochs',
      role: 'Head of Customer Support',
      company: 'DealDash',
    },
  ],
  'Professional Services': [
    {
      text: "[Front] is going to significantly increase communication quality while, at the same time, reduce the daily email burden on our team. You can't do better than that.",
      author: 'Joe Daggar',
      role: 'VP of Technology and Enablement',
      company: 'PuzzleHR',
    },
    {
      text: 'We saw nice development in NPS since we started Front, from 60 to 80.',
      author: 'Clément Ouizille',
      role: 'Co-founder',
      company: 'Convelio',
    },
    {
      text: '[We] optimized [our] support operations … after consolidating channels, ensuring seamless integration. We saw [our] CSAT score increase by 23% in less than a year.',
      author: 'Liz Sanchez',
      role: 'Senior Customer Support Manager',
      company: 'Spruce',
    },
  ],
  'Retail': [
    {
      text: 'Front helps us maintain that delicate balance between speed and quality.',
      author: 'Mary Ochs',
      role: 'Head of Customer Support',
      company: 'DealDash',
    },
    {
      text: 'We pride ourselves on having the best customer support team in the world, so we needed a solution that could match that level of excellence. The answer was Front.',
      author: 'Mary Ochs',
      role: 'Head of Customer Support',
      company: 'DealDash',
    },
    {
      text: 'The different collaboration features in Front allow us to quickly bring in expertise from different team members without having to switch platforms.',
      author: 'Mary Ochs',
      role: 'Head of Customer Support',
      company: 'DealDash',
    },
    {
      text: 'Front helps us maintain a delicate balance between speed and quality in our customer interactions. It empowers our team to work efficiently, collaborate seamlessly, and consistently deliver the exceptional service for which DealDash is known.',
      author: 'Mary Ochs',
      role: 'Head of Customer Support',
      company: 'DealDash',
    },
  ],
  'Logistics': [
    {
      text: 'We are able to handle a similar volume of emails as we had in 2019 with 25% less staff. It has really made us more efficient.',
      author: 'Lukasz Biegus',
      role: 'CTO',
      company: 'TBR Global Chauffering',
    },
    {
      text: '[With Front] we are doing 400-600 automatic quotes per business day. This leads to more won business, and we are closing more deals.',
      author: 'Mike Nasceif Jr.',
      role: 'Operational Process and Training Manager',
      company: 'Load One',
    },
    {
      text: 'Front is a no-brainer for teams that have a high volume of email, share an inbox, or collaborate with each other. I love the platform. I wish I had it years ago.',
      author: 'Marissa Taylor',
      role: 'Manager of Business Process Improvement',
      company: 'Estes Forwarding',
    },
    {
      text: 'With Front, we can predict business trends, better prepare for seasonality, and make appropriate plans for team members vacations.',
      author: 'Douglas Kurtz',
      role: 'Director of Aviation Services',
      company: 'MNX Global Logistics',
    },
  ],
} as const;

export const getRandomQuoteByIndustry = (industry: string): Quote => {
  const industryQuotes = QUOTES_BY_INDUSTRY[industry] || QUOTES_BY_INDUSTRY['Technology'];
  const randomIndex = Math.floor(Math.random() * industryQuotes.length);

  return industryQuotes[randomIndex];
};
